import signedFetch from '../util/signedFetch.js';
import { toQueryString } from '../../../../utils/url.js';

export default function* getEltGamesApi(filters) {
  const { contentCode, title, template, max } = filters;

  const queryParams = {
    ...(contentCode && { contentCode }),
    ...(title && { title }),
    ...(template && { template }),
    ...(max !== undefined ? { max } : { max: 0 })
  };

  const url = `${__API_BASE__}/content/games${toQueryString(queryParams)}`;
  return yield signedFetch('getEltGames', url, 'GET', null, null, false, true);
}

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import classnames from 'classnames';
import compose from '../../../../utils/compose/compose.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import PageHeading from '../../../../components/PageHeading/PageHeading';
import styles from './ContentPreview.scss';
import {
  getContentPreviewRequest,
  getEltGamesRequest,
  getEReaderProductsRequest,
  setGamesPreviewContentCode,
  resetGamesPreviewContentCode
} from '../../../../redux/actions/contentPreview';

import SearchInput from '../../../../components/SearchInput/SearchInput';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { featureIsEnabled, getEnvType, isLocal } from '../../../../globals/envSettings';
import TextInput from '../../../../components/TextInput/TextInput';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal.js';
import ContentPreviewLaunch from './ContentPreviewLaunch';
import ContentPreviewModal from './ContentPreviewModal';
import ElementWithText from '../../../../components/ElementWithText/ElementWithText';
import IconEmblem from '../../../../components/IconEmblem/IconEmblem';
import { GLYPHS } from '../../../../components/SVGIcon/SVGIcon.js';
import getAbsoluteAndRecentTime from '../../../../utils/date/getAbsoluteAndRecentTime.js';
import preventBodyScroll from '../../../../utils/dom/preventBodyScroll';
import {
  sourceOptions,
  contentAreaPlatformOptions,
  themeOptions,
  getProductsPreviewColumns,
  getContentPreviewColumns,
  getEltGamesPreviewColumns,
  getEReaderProductsPreviewColumns
} from './tableData';
import { selfSelectedUserRoles, MODAL_CLOSE_LINK_KEY } from '../../../../globals/cptConstants';
import {
  openStructuredContentPlayerModal,
  setModalBasePath,
  setPreviewRole
} from '../../../../redux/actions/structuredContentPlayer';
import Button, { buttonTypes } from '../../../../components/Button/Button';
import { SOURCES, NA } from '../../../../globals/contentPreviewConstants.js';
import ContentPreviewTable from './ContentPreviewTable';
import changeRoleToProductVariant from '../../../../components/StructuredContentPlayer/structuredContentPlayerUtils.js';
import { deleteProductRequest, clearDeleteState } from '../../../../redux/actions/deleteProduct.js';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner.js';
import userRoles from '../../../../globals/userRoles.js';
import mapDivisionToLibrary from '../../../../../sharedNodeBrowser/mapDivisionToLibrary';

function ContentPreview({
  getContentPreviewRequestData,
  getEltGamesRequestData,
  getEReaderProductsRequestData,
  deleteProductRequestCall,
  clearDeleteStateAction,
  loading,
  success,
  results,
  isDeleteInProgress,
  isDeleteReady,
  deleteResponse,
  isEltbookPreview,
  isEltGamesContentArea,
  isEpsContentArea,
  isEreaderContentArea,
  localizedContent: { contentPreview: content },
  openStructuredContentPlayerModalAction,
  setModalBasePathAction,
  setPreviewRoleAction,
  setGamesPreviewContentCodeAction,
  resetGamesPreviewContentCodeAction,
  eltGamesPreviewContentCode,
  userRole
}) {
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [gameFilters, setGameFilters] = useState({});
  const [bookPath, setBookPath] = useState('');
  const [themes, setThemes] = useState([]);
  const [source, setSource] = useState('');
  const [max, setMax] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [dataToDelete, setDataToDelete] = useState({});
  const [epsContentFilteredResults, setEpsContentFilteredResults] = useState(results);
  const navigate = useNavigate();
  const initialGridElement = document.querySelector('.grid');
  const [gridElementDimension, setGridElementDimension] = useState(initialGridElement?.offsetWidth);

  let sourceUsed = source;
  if (sourceUsed === SOURCES.PROD) {
    sourceUsed = '';
  }

  const handleFilter = value => {
    setEpsContentFilteredResults(
      results.filter(item => {
        const lowerCaseValue = value.toLowerCase();
        const searchableProperties = [item.isbn, item.title, item.author, item.subtitle];
        return searchableProperties.some(property =>
          property
            ? property
                .toString()
                .toLocaleLowerCase()
                .includes(lowerCaseValue)
            : property
        );
      })
    );
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        handleFilter(value);
      }, 300),
    [results]
  );

  useEffect(() => {
    let defaultSource = isEpsContentArea ? SOURCES.ELTCORE : SOURCES.UAT;

    if (isEltbookPreview) {
      defaultSource = SOURCES.ELTBOOK;
    }

    const defaultMax = isEpsContentArea ? 9999 : 10;

    if (getEnvType() === SOURCES.PROD) {
      defaultSource = SOURCES.PROD;
    }

    if (isEreaderContentArea) {
      getEReaderProductsRequestData({ sort: 'DESC', max: 10 });
    } else if (isEltGamesContentArea) {
      getEltGamesRequestData({ ...gameFilters, max });
    } else {
      getContentPreviewRequestData({
        source: defaultSource,
        id,
        title,
        max: defaultMax,
        isEreader: true
      });
      setSource(defaultSource);
      setMax(defaultMax);
    }

    sessionStorage.setItem(MODAL_CLOSE_LINK_KEY, window.location.pathname);
    openStructuredContentPlayerModalAction();
    preventBodyScroll(false);
    setModalBasePathAction(window.location);

    const gridElement = document.querySelector('.grid');
    window.addEventListener('resize', () => {
      setGridElementDimension(gridElement?.offsetWidth);
    });
  }, []);

  useEffect(() => {
    if (results && results.length > 0) {
      handleFilter('');
      setThemes(() => {
        results.map((result, index) => {
          themes[index] = result.metadata?.bookSettings || 'default';
          return null;
        });
        return themes;
      });
    }
  }, [JSON.stringify(results)]);

  useEffect(() => () => debouncedSearch.cancel(), [debouncedSearch]);

  const handleDropdownUpdate = value => {
    if (isEreaderContentArea) {
      getEReaderProductsRequestData({ sort: 'DESC', max, title, id, bookPath });
    } else if (isEltGamesContentArea) {
      getEltGamesRequestData({ ...gameFilters, max });
    } else {
      setSource(value);
      getContentPreviewRequestData({ source: value, id, title, max });
    }
  };

  const handleDropdownThemeUpdate = (value, key) => {
    const updatedThemes = [...themes];
    updatedThemes[key] = value;
    setThemes(updatedThemes);
  };

  const handleEltcorePreview = (role, contentCode = '') => {
    setPreviewRoleAction(role);
    const viewVariant = changeRoleToProductVariant(role);
    navigate(`/launch/${viewVariant}/${contentCode}`);
  };

  const handleEreaderPreview = (book, theme, division = '') => {
    if (isEltbookPreview) {
      const library = mapDivisionToLibrary[division];
      window.open(`/launch-ereader/book/${library}--${book}/theme/${theme}`, '_blank');
    } else {
      navigate(`/launch-ereader/book/${book}/theme/${theme}`);
    }
  };

  const handleEltcoreDelete = (contentCode = '', isbn = '') => {
    setOpenModal(true);
    setDataToDelete({ contentCode, isbn });
  };

  const _onCancel = () => {
    setOpenModal(false);
  };

  const contentAreaDeleteButton = (key, contentCode = '', isbn = '') => (
    <div className={styles.previewButtonsContainer}>
      <Button
        key={`id_${key}`}
        text={content.delete_product}
        onClick={() => handleEltcoreDelete(contentCode, isbn)}
        type={buttonTypes.CLOSE_BOLD}
      />
    </div>
  );

  const contentAreaPreviewButton = (key, contentCode = '', division = '') => {
    if (isEltGamesContentArea) {
      return (
        <Button
          key={`id_${key}_preview`}
          text={content.preview}
          type={buttonTypes.SECONDARY}
          onClick={() => {
            document.body.style.overflow = 'hidden';
            setGamesPreviewContentCodeAction(contentCode);
          }}
        />
      );
    }

    if (source === SOURCES.ELTCORE) {
      return (
        <div className={styles.previewButtonsContainer}>
          <Button
            key={`id_${key}_teacher`}
            text={content.preview_as_teacher}
            onClick={() => handleEltcorePreview(selfSelectedUserRoles.SELF_TEACHER, contentCode)}
            type={buttonTypes.SECONDARY}
          />
          <Button
            key={`id_${key}_student`}
            text={content.preview_as_student}
            onClick={() => handleEltcorePreview(selfSelectedUserRoles.SELF_LEARNER, contentCode)}
            type={buttonTypes.SECONDARY}
          />
        </div>
      );
    }

    const strippedBookName = contentCode.replace('/', '--').replace('.epub', '');

    return (
      <Button
        key={`id_${key}_preview`}
        text={content.preview}
        type={buttonTypes.SECONDARY}
        disabled={!featureIsEnabled('ereader-content-tokens')}
        onClick={() => handleEreaderPreview(strippedBookName, themes[key], division)}
      />
    );
  };

  const renderNonCustomer = (item, key) => {
    if (item.entityEvent) {
      const userDetails = item.entityEvent[key];
      if (userDetails) {
        const { firstName, lastName, userEmail } = userDetails;
        return <a href={`mailto:${userEmail}`}>{`${firstName} ${lastName}`}</a>;
      }
    }
    return NA;
  };

  const generateContentPreviewRows = data => {
    const isDeleteButtonHidden = [userRoles.OUP_CONTENT_REVIEWER, userRoles.EXTERNAL_CONTENT].includes(userRole);
    const rows = [];

    if (isEltGamesContentArea) {
      data?.forEach((item, key) => {
        rows.push({
          id: `id${key}`,
          cells: [
            item.title,
            item.contentCode,
            item.gitanaId,
            item.customMetadata?.template || NA,
            item.timestamp ? new Date(item.timestamp).toLocaleString() : NA,
            item.stored ? new Date(item.stored).toLocaleString() : NA,
            contentAreaPreviewButton(key, item.contentCode, item.division)
          ]
        });
      });
      return rows;
    }

    if (isEpsContentArea) {
      data?.forEach((item, key) => {
        rows.push({
          id: `id${key}`,
          cells: [
            `${sourceUsed}`.toUpperCase(),
            item.title,
            item.cefr_level || NA,
            item.isbn,
            item.entityEvent ? new Date(item.entityEvent.dateCreated).toLocaleString() : NA,
            renderNonCustomer(item, 'createdByUserDetails'),
            item.entityEvent ? new Date(item.entityEvent.dateModified).toLocaleString() : NA,
            renderNonCustomer(item, 'modifiedByUserDetails'),
            contentAreaPreviewButton(key, item.contentCode, item.division),
            !isDeleteButtonHidden ? contentAreaDeleteButton(key, item.contentCode, item.isbn) : null
          ]
        });
      });
      return rows;
    }

    if (isEreaderContentArea) {
      if (!Array.isArray(data)) {
        return [];
      }

      data?.forEach((item, key) => {
        rows.push({
          id: `id${key}`,
          cells: [
            item.bookPath?.substring(0, 70),
            item.title || item.metadata?.title || NA,
            item.metadata?.author || NA,
            item.created,
            item.updated,

            <details key={`details_${key}`}>
              <summary>{content.metadata}</summary>
              {item.metadata && Object.entries(item.metadata) ? (
                <ol>
                  {Object.entries(item.metadata).map(([childKey, value]) => (
                    <li key={`child_${childKey}`}>
                      {childKey}: {value}
                    </li>
                  ))}
                </ol>
              ) : (
                <p>{content.no_metadata}</p>
              )}
            </details>,
            <Dropdown
              key={`theme${key}`}
              label={content.filter_source}
              options={themeOptions}
              value={themes[key]}
              onChange={val => handleDropdownThemeUpdate(val, key)}
              labelHidden
            />,
            contentAreaPreviewButton(key, item._id)
          ]
        });
      });
      return rows;
    }

    data?.forEach((item, key) => {
      rows.push({
        id: `id${key}`,
        cells: [
          `${sourceUsed} LOR`.toUpperCase(),
          <TextInput value={item.objectTitle} key={key} />,
          item.objectResourceID,
          item.contentType,
          getAbsoluteAndRecentTime(item.firstPublishDate),
          getAbsoluteAndRecentTime(item.lastPublishDate),
          <ContentPreviewLaunch item={item} key={key} content={content} />
        ]
      });
    });
    return rows;
  };

  const showConfirmationModal = () => {
    if (isDeleteInProgress) {
      return (
        <ConfirmationModal
          title={content.confirmation_title}
          body={<LoadingSpinner />}
          positiveClickText={content.loading}
        />
      );
    }

    if (isDeleteReady) {
      return (
        <ConfirmationModal
          title={content.confirmation_title}
          body={deleteResponse}
          positiveClickText={content.close}
          positiveClick={() => {
            clearDeleteStateAction();
            setOpenModal(false);
          }}
        />
      );
    }

    return (
      <ConfirmationModal
        title={content.confirmation_title}
        body={content.confirmation_body}
        positiveClickText={content.delete_confirmation}
        positiveClick={() => {
          deleteProductRequestCall(dataToDelete);
        }}
        negativeClickText={content.cancel_button}
        negativeClick={_onCancel}
      />
    );
  };

  useEffect(() => {
    sessionStorage.setItem(MODAL_CLOSE_LINK_KEY, window.location.pathname);
    openStructuredContentPlayerModalAction();
    preventBodyScroll(false);
    setModalBasePathAction(window.location);
  }, []);

  const renderTable = () => {
    const data = isEpsContentArea ? epsContentFilteredResults : results;
    const rows = generateContentPreviewRows(data);
    let columns = getContentPreviewColumns(content);
    if (isEpsContentArea) {
      columns = getProductsPreviewColumns(content);
    } else if (isEltGamesContentArea) {
      columns = getEltGamesPreviewColumns(content);
    } else if (isEreaderContentArea) {
      columns = getEReaderProductsPreviewColumns(content);
    }

    return (
      <ContentPreviewTable
        rows={rows}
        columns={columns}
        success={success}
        loading={loading}
        customClass={styles.tableAccordionPosition}
      />
    );
  };

  const searchButtonAction = value => {
    if (isEreaderContentArea) {
      getEReaderProductsRequestData({ sort: 'DESC', max: value || max, title, id, bookPath });
    } else if (isEltGamesContentArea) {
      getEltGamesRequestData({ ...gameFilters, max: value || max });
      setGameFilters({});
    } else {
      getContentPreviewRequestData({ source, id, title, max: value || max });
    }
  };

  const renderFilters = () => {
    if (isEpsContentArea) {
      return (
        <div className={styles.filter}>
          <SearchInput placeholder={content.eps_content_search_product_label} onChange={debouncedSearch} />
        </div>
      );
    }

    if (isEltGamesContentArea) {
      return (
        <>
          <div className={styles.filter}>
            <SearchInput
              placeholder={content.elt_games_content_code_search_label}
              value={gameFilters.contentCode || ''}
              onChange={value => {
                setGameFilters({ contentCode: value });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  searchButtonAction();
                }
              }}
              buttonAction={() => {
                searchButtonAction();
              }}
            />
          </div>
          <div className={styles.filter}>
            <SearchInput
              placeholder={content.elt_games_title_search_label}
              value={gameFilters.title || ''}
              onChange={value => {
                setGameFilters({ title: value });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  searchButtonAction();
                }
              }}
              buttonAction={() => {
                searchButtonAction();
              }}
            />
          </div>
          <div className={styles.filter}>
            <SearchInput
              placeholder={content.elt_games_template_search_label}
              value={gameFilters.template || ''}
              onChange={value => {
                setGameFilters({ template: value });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  searchButtonAction();
                }
              }}
              buttonAction={() => {
                searchButtonAction();
              }}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles.filter}>
          <SearchInput
            placeholder={content.search_id_label}
            value={id}
            onChange={value => {
              setTitle('');
              setBookPath('');
              setId(value);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                searchButtonAction();
                setId('');
              }
            }}
            buttonAction={() => {
              searchButtonAction();
              setId('');
            }}
          />
        </div>
        <div className={styles.filter}>
          <SearchInput
            placeholder={content.search_title_label}
            value={title}
            onChange={value => {
              setId('');
              setBookPath('');
              setTitle(value);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                searchButtonAction();
                setTitle('');
              }
            }}
            buttonAction={() => {
              searchButtonAction();
              setTitle('');
            }}
          />
        </div>
        <div className={styles.filter}>
          <SearchInput
            placeholder={content.search_bookpath_label}
            value={bookPath}
            onChange={value => {
              setId('');
              setTitle('');
              setBookPath(value);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                searchButtonAction();
                setBookPath('');
              }
            }}
            buttonAction={() => {
              searchButtonAction();
              setBookPath('');
            }}
          />
        </div>
      </>
    );
  };

  let pageTitle = content.lor_heading;
  if (isEpsContentArea) {
    pageTitle = content.eps_content_title;
  } else if (isEreaderContentArea) {
    pageTitle = content.ereader_content_title;
  } else if (isEltGamesContentArea) {
    pageTitle = content.elt_games_content_title;
  }

  // search box which should currently query the “term” field (this checks isbn, title, author and subtitle)
  return (
    // 78 rem * 16px = 1248px
    <>
      <div className={classnames(styles.contentPreviewWrapper, gridElementDimension >= 1248 && styles.transform)}>
        <div>
          <ContentPreviewModal />
        </div>
        <div>{openModal ? showConfirmationModal() : null}</div>
        {isEreaderContentArea || isEltGamesContentArea ? null : (
          <div className={styles.sourceFilter}>
            <Dropdown
              id="source"
              label={content.filter_source}
              options={isEpsContentArea ? contentAreaPlatformOptions : sourceOptions}
              value={source}
              onChange={val => handleDropdownUpdate(val)}
              labelHidden
            />
          </div>
        )}
        <div className={styles.resultsNumber}>
          {isEpsContentArea ? null : (
            <Dropdown
              id="max"
              label={content.max_results}
              options={[
                {
                  value: 10,
                  text: content.page_size_10
                },
                {
                  value: 100,
                  text: content.page_size_100
                },
                {
                  value: 250,
                  text: content.page_size_250
                },
                {
                  value: 1000,
                  text: content.page_size_1000
                },
                {
                  value: 2000,
                  text: content.page_size_2000
                },
                {
                  value: 3000,
                  text: content.page_size_3000
                },
                {
                  value: 4000,
                  text: content.page_size_4000
                },
                {
                  value: 5000,
                  text: content.page_size_5000
                },
                {
                  value: 9999,
                  text: content.page_size_max
                }
              ]}
              value={max}
              onChange={value => {
                setMax(value);
                searchButtonAction(value);
              }}
              labelHidden
            />
          )}
        </div>
        <PageHeading title={pageTitle} />
        <br />
        <div className={styles.contentPreviewTableContainer}>
          <div className={styles.filters}>{renderFilters()}</div>
          {isLocal() && !isEreaderContentArea && (
            <div className={styles.alertContainer}>
              <ElementWithText text={isEpsContentArea ? content.eps_content_alert : content.local_alert}>
                <IconEmblem icon={GLYPHS.ICON_WARNING_CIRCLE} colour="none" />
              </ElementWithText>
            </div>
          )}
          {renderTable()}
        </div>
      </div>
      <div className={classnames(styles.gamesPreviewLayer, eltGamesPreviewContentCode && styles.open)}>
        <div className={styles.gamesPreviewHeader}>
          <Button
            key="id_1"
            text={content.close_preview}
            onClick={() => {
              document.body.style.overflow = '';
              resetGamesPreviewContentCodeAction();
            }}
            type={buttonTypes.CLOSE_BOLD}
          />
        </div>
        {eltGamesPreviewContentCode && (
          <iframe
            src={`/content/apps/elt/nextgen_games/${eltGamesPreviewContentCode}/index.html`}
            title="game preview"
          />
        )}
      </div>
    </>
  );
}

ContentPreview.propTypes = {
  getContentPreviewRequestData: PropTypes.func.isRequired,
  getEltGamesRequestData: PropTypes.func.isRequired,
  getEReaderProductsRequestData: PropTypes.func.isRequired,
  deleteProductRequestCall: PropTypes.func.isRequired,
  clearDeleteStateAction: PropTypes.func.isRequired,
  deleteResponse: PropTypes.string,
  isDeleteInProgress: PropTypes.bool,
  isDeleteReady: PropTypes.bool,
  results: PropTypes.object,
  success: PropTypes.bool,
  loading: PropTypes.bool,
  localizedContent: PropTypes.object,
  isEltbookPreview: PropTypes.bool,
  isEltGamesContentArea: PropTypes.bool,
  isEpsContentArea: PropTypes.string,
  isEreaderContentArea: PropTypes.bool,
  setPreviewRoleAction: PropTypes.func,
  openStructuredContentPlayerModalAction: PropTypes.func,
  setModalBasePathAction: PropTypes.func,
  setGamesPreviewContentCodeAction: PropTypes.func,
  resetGamesPreviewContentCodeAction: PropTypes.func,
  eltGamesPreviewContentCode: PropTypes.string,
  userRole: PropTypes.string
};

const mapStateToProps = state => {
  const {
    contentPreview: {
      loading,
      success,
      results,
      isDeleteInProgress,
      isDeleteReady,
      deleteResponse,
      eltGamesPreviewContentCode
    },
    identity: { role }
  } = state;
  return {
    loading,
    success,
    results,
    isDeleteInProgress,
    isDeleteReady,
    deleteResponse,
    eltGamesPreviewContentCode,
    userRole: role
  };
};

const mapDispatchToProps = {
  getContentPreviewRequestData: getContentPreviewRequest,
  getEltGamesRequestData: getEltGamesRequest,
  getEReaderProductsRequestData: getEReaderProductsRequest,
  deleteProductRequestCall: deleteProductRequest,
  clearDeleteStateAction: clearDeleteState,
  openStructuredContentPlayerModalAction: openStructuredContentPlayerModal,
  setModalBasePathAction: setModalBasePath,
  setPreviewRoleAction: setPreviewRole,
  setGamesPreviewContentCodeAction: setGamesPreviewContentCode,
  resetGamesPreviewContentCodeAction: resetGamesPreviewContentCode
};

export default compose(
  withLocalizedContent('contentPreview'),
  connect(mapStateToProps, mapDispatchToProps)
)(ContentPreview);
